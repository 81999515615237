<template>
  <div>
    <dashboard
      :uppy="uppy"
      :plugins="[
        'GoogleDrive',
        'Dropbox',
        'Box',
        'Instagram',
        'Facebook',
        'OneDrive',
        'Audio',
        'Url',
        'Webcam',
        'ScreenCapture'
      ]"
      :props="config"
    />
  </div>
</template>

<script>
import Uppy from '@uppy/core'
import { Dashboard } from '@uppy/vue'
import GoogleDrive from '@uppy/google-drive'
import Dropbox from '@uppy/dropbox'
import Box from '@uppy/box'
import Instagram from '@uppy/instagram'
import Facebook from '@uppy/facebook'
import OneDrive from '@uppy/onedrive'
import Webcam from '@uppy/webcam'
import ScreenCapture from '@uppy/screen-capture'
import Audio from '@uppy/audio'
import Url from '@uppy/url'
import XHRUpload from '@uppy/xhr-upload'

// import DropTarget from '@uppy/drop-target'

import '@uppy/core/dist/style.css'
import '@uppy/dashboard/dist/style.css'
import '@uppy/audio/dist/style.css'
import '@uppy/webcam/dist/style.css'
import '@uppy/screen-capture/dist/style.css'

export default {
  name: 'Uploader',

  components: {
    Dashboard
  },

  data() {
    return {
      config: {
        note: 'Upload 6 files total, and each file is 100MB limits',
        metaFields: [
          { id: 'name', name: 'Name', placeholder: 'file name' },
          { id: 'caption', name: 'Caption', placeholder: 'describe what the image is about' }
        ],
        width: 600
      }
    }
  },

  computed: {
    uppy() {
      return new Uppy({ id: 'uppy-uploader', autoProceed: false, debug: true })
        .use(GoogleDrive, { companionUrl: this.$config.uppy.companionUrl })
        .use(Dropbox, { companionUrl: this.$config.uppy.companionUrl })
        .use(Box, { companionUrl: this.$config.uppy.companionUrl })
        .use(Instagram, { companionUrl: this.$config.uppy.companionUrl })
        .use(Facebook, { companionUrl: this.$config.uppy.companionUrl })
        .use(OneDrive, { companionUrl: this.$config.uppy.companionUrl })
        .use(Webcam, {
          onBeforeSnapshot: () => Promise.resolve(),
          countdown: false,
          modes: [
            'video-audio',
            'video-only',
            'audio-only',
            'picture',
          ],
          mirror: true,
          videoConstraints: {
            facingMode: 'user',
            width: { min: 720, ideal: 1280, max: 1920 },
            height: { min: 480, ideal: 800, max: 1080 },
          },
          showRecordingLength: false,
          preferredVideoMimeType: null,
          preferredImageMimeType: null,
          locale: {},
        })
        .use(Audio)
        .use(XHRUpload, {
          endpoint: this.$config.uppy.endpoint,
          fieldName: 'files',
          metaFields: [],
          headers: {}
        })
        .use(Url, { companionUrl: this.$config.uppy.companionUrl })
        .use(ScreenCapture, { })
      // .use(DropTarget, { target: document.body })
    }
  },

  mounted() {
    this.uppy.on('complete', result => {
      console.log(result)
      console.log('successful files:', result.successful)
      console.log('failed files:', result.failed)
    })
  },

  beforeDestroy () {
    this.uppy.close({ reason: 'unmount' })
  }
}
</script>