var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('dashboard',{attrs:{"uppy":_vm.uppy,"plugins":[
      'GoogleDrive',
      'Dropbox',
      'Box',
      'Instagram',
      'Facebook',
      'OneDrive',
      'Audio',
      'Url',
      'Webcam',
      'ScreenCapture'
    ],"props":_vm.config}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }