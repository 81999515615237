<template>
  <div class="flex flex-col p-12">
    <div>Test</div>
    <div class="flex flex-col space-y-4 w-1/2">
      <div class="flex flex-col space-y-1 border-1 border-gray-400">
        <p class="bg-gray-100 p-2">Setup database</p>
        <div class="flex flex-row">
          <button class="border-1 border-gray-500" @click="setupDatabase">Setup Database</button>
          <button class="border-1 border-gray-500" @click="clearDatabase">Clear Database</button>
        </div>
      </div>

      <div class="flex flex-col space-y-1 border-1 border-gray-400">
        <p class="bg-gray-100 p-2">Signup</p>
        <input v-model="auth.username" placeholder="username" type="text" class="bg-gray-100"/>
        <input v-model="auth.email" placeholder="email" type="text" class="bg-gray-100"/>
        <input v-model="auth.password" placeholder="password" type="password" class="bg-gray-100"/>
        <div class="flex flex-row">
          <button class="border-1 border-gray-500" @click="signup">Singup</button>
          <button v-if="loggedIn" class="border-1 border-gray-500" @click="logout">Logout</button>
        </div>
      </div>

      <div class="flex flex-col space-y-1 border-1 border-gray-400">
        <p class="bg-gray-100 p-2">Login</p>
        <input v-model="auth.email" placeholder="email" type="text" class="bg-gray-100"/>
        <input v-model="auth.password" placeholder="password" type="password" class="bg-gray-100"/>
        <div class="flex flex-row">
          <button class="border-1 border-gray-500" @click="login">Login</button>
          <button v-if="loggedIn" class="border-1 border-gray-500" @click="logout">Logout</button>
        </div>
      </div>

      <div class="flex flex-col space-y-1 border-1 border-gray-400">
        <p class="bg-gray-100 p-2">New note</p>
        <input v-model="note.title" placeholder="title" type="text" class="bg-gray-100"/>
        <input v-model="note.type" placeholder="type" type="text" class="bg-gray-100"/>
        <input v-model="note.data" placeholder="data" type="text" class="bg-gray-100"/>
        <div class="flex flex-row">
          <button class="border-1 border-gray-500" @click="createNote">create</button>
        </div>
      </div>

      <div class="flex flex-col space-y-1 border-1 border-gray-400">
        <p class="bg-gray-100 p-2">Find note and delete notes</p>
        <input v-model="note.title" placeholder="title" type="text" class="bg-gray-100"/>
        <input v-model="note.type" placeholder="type" type="text" class="bg-gray-100"/>
        <div class="flex flex-row">
          <button class="border-1 border-gray-500" @click="findNote">Find</button>
        </div>
        <div class="flex flex-col">
          <template v-for="n in notes">
            <div class="flex flex-row space-x-2" :key="n.objectId">
              <p @click="selectedNote = n">{{ n.title }}</p>
              <p>{{ n.type }}</p>
              <p>{{ n.owner.username }}</p>
              <p>
                <button class="border-1 border-gray-500" @click.stop="deleteNote(n)">Delete</button>
              </p>
            </div>
          </template>
        </div>

        <div class="flex flex-col space-y-1 border-1 border-gray-400">
          <p class="bg-gray-100 p-2">Update note</p>
          <div class="p-4">
            <template v-if="selectedNote">
              <input v-model="note.title" placeholder="title" type="text" class="bg-gray-100"/>
              <input v-model="note.data" placeholder="data" type="text" class="bg-gray-100"/>
              <button class="border-1 border-gray-500" @click="updateNote">Update</button>
            </template>
          </div>
        </div>

        <div class="flex flex-col space-y-1 border-1 border-gray-400">
          <p class="bg-gray-100 p-2">Upload attachment</p>
          <div class="p-1">
            <uploader></uploader>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Parse, * as core from '@/api/core'
import Uploader from '@/components/uploader'

export default {
  name: 'TestPage',

  components: {
    Uploader
  },

  data () {
    return {
      auth: {
        username: 'koder',
        email: 'koder.chen.1984@gmail.com',
        password: '123'
      },
      note: {
        title: 'new note',
        type: 'text',
        data: 'Hello world'
      },
      notes: [],
      selectedNote: null
    }
  },

  computed: {
    loggedIn() {
      return core.isAuthenticated()
    }
  },

  methods: {
    async setupDatabase() {
      try {
        await Parse.Cloud.run('setupDatabase', {}, { useMasterKey: true })
        console.log('databse setup successfully!')
      } catch(error) {
        console.error(error)
      }
    },

    async clearDatabase() {
      try {
        await Parse.Cloud.run('clearDatabase', {}, { useMasterKey: true })
        console.log('databse clear successfully!')
      } catch(error) {
        console.error(error)
      }
    },

    // authentication related
    async signup() {
      const user = new Parse.User()
      user.set('username', this.auth.username)
      user.set('email', this.auth.email)
      user.set('password', this.auth.password)
      try {
        await user.signUp()
      } catch(error) {
        console.error(error)
      }
    },

    async login() {
      try {
        let user = await Parse.User.logIn(this.auth.email, this.auth.password)
        console.log(user)
      } catch(error) {
        console.error(error)
      }
    },

    async logout() {
      try {
        await Parse.User.logOut()
      } catch(error) {
        console.error(error)
      }
    },

    async updateProfile(updates) {
      try {
        let user = await Parse.User.current()
        Object.keys(updates).forEach(key => {
          user.set(key, updates[key])
        })
        await user.save()
      } catch(error) {
        console.error(error)
      }
    },

    async startResetPassword() {
      try {
        await Parse.User.requestPasswordReset( this.auth.email )
      } catch(error) {
        console.error(error)
      }
    },

    async cancelAccount() {
      try {
        let user = await Parse.User.current()
        if (user) {
          user.set('status', 'canceled')
          await user.save()
        }
      } catch(error) {
        console.error(error)
      }
    },
  
    // notes related
    async createNote() {
      try {
        if (!core.isAuthenticated()) {
          console.error('not authenticated')
          return
        }
        let note = core.newObjectWithData('Note', this.note)
        await note.save()
        console.log('successfully save note')
      } catch(error) {
        console.error(error)
      }
    },

    async updateNote() {
      try {

        let note = this.selectedNote
        if (note) {
          await core.updateObjectOnly(note, {
            title: this.note.title,
            data: this.note.data
          })
        }
      } catch(error) {
        console.error(error)
      }
    },

    async deleteNote(n) {
      try {
        await n.destroy()
      } catch(error) {
        console.error(error)
      }
    },

    async findNote() {
      try {
        let query = core.getQuery('Note')
        let notes = await query.find()
        this.notes = notes
      } catch(error) {
        console.error(error)
      }
    },

    async getNote() {

    },
    // labels related
    async createLabel() {

    },

    async deleteLabel() {

    },

    async updateLabel() {

    },

    async findLabels() {

    },

    async createTodo() {

    },

    async updateTodo() {

    },

    async deleteTodo() {

    },

    async findTodos() {

    },
    // reminds
    async createReminder() {

    },

    async updateReminder() {

    },

    async deleteReminder() {
      
    },

    async findReminders() {

    }
  },

  mounted() {
    console.log(Parse)
  }
}
</script>